// eslint-disable-next-line @nx/enforce-module-boundaries
import Modal from 'libs/common-components/src/lib/modal/modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardsSlider } from '@orascom/common-components';
import styles from './destination-gallery.module.scss';
import { v4 as uuidv4 } from 'uuid';

export interface DestinationGalleryProps {
  gallery: string[];
}

export function DestinationGallery({
  gallery,
}: Readonly<DestinationGalleryProps>) {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const { t } = useTranslation();

  const handleShowModal = (image: string) => {
    setModalImage(image);
    setShowModal(true);
    console.log('bla');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  const reducedArr = gallery.reduce((acc: any[], image: string) => {
    if (image === modalImage) {
      return [image, ...acc];
    }
    return [...acc, image];
  }, []);
  return (
    <div className={styles['wrapper']}>
      {Boolean(gallery.length) && (
        <>
          <h3 className={styles['title']}>{t('gallery')}</h3>
          <CardsSlider spaceBetween={20}>
            {gallery.map((g) => (
              <button key={uuidv4()} onClick={() => handleShowModal(g)}>
                <img
                  src={g}
                  alt=""
                  className={styles['image']}
                  loading="lazy"
                />
              </button>
            ))}
          </CardsSlider>
        </>
      )}
      <Modal show={showModal} onClose={handleCloseModal}>
        <CardsSlider spaceBetween={20} slidesPerView={1}>
          {reducedArr.map((g) => (
            <img
              key={g}
              src={g}
              alt=""
              className={styles['image']}
              style={{ width: '100%' }}
              loading="lazy"
            />
          ))}
        </CardsSlider>
      </Modal>
    </div>
  );
}

export default DestinationGallery;

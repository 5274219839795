import { notificationTimeCalculator } from '@orascom/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import updatesIcon from '../../assets/icons/updates.svg';
import { useContext } from 'react';
import styles from './recent-updates.module.scss';
import { NOTIFICATIONS_CONTEXT } from '@orascom/broker-sales-man-common-components';

export function RecentUpdates() {
  const { t } = useTranslation();
  const { notifications } = useContext(NOTIFICATIONS_CONTEXT);
  const splicedNotification = notifications?.slice(0, 2);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <h3>{t('recentUpdates')}</h3>
        <Link to={ROUTES['Notifications'].path} className={styles['anchor']}>
          <span className="anchor">{t('viewAllUpdates')}</span>
          <img src={arrowIcon} alt="" role="presentation" />
        </Link>
      </div>
      <div className={styles['flex']}>
        {splicedNotification?.length ? (
          splicedNotification?.map((u) => (
            <div key={u.id} className={styles['news']}>
              <div className={styles['details']}>
                <div className={styles['icon']}>
                  <img src={updatesIcon} alt="" role="presentation" />
                </div>
                <p className={styles['content']}>{u.message}</p>
              </div>
              <div className={styles['time']}>
                {notificationTimeCalculator(u.created_at)}
              </div>
            </div>
          ))
        ) : (
          <div className={styles['news']}>
            <div className={styles['details']}>
              <p className={styles['empty-placholder']}>
                {t('noRecentUpdates')}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentUpdates;

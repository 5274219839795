import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import styles from './deal-lifecycle.module.scss';
import ProgressBar from '../common/progress-bar/progress-bar';
import InfoIcon from '../../assets/icons/info.svg';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { AnalyticsLeadsCount } from '../../definitions/interfaces/analytics';
import { FilterValue, UserRole } from '@orascom/api-interfaces';
import queryString from 'query-string';
import {
  AnalyticsFilter,
  useAnalyticsFilters,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';

interface DealLifeCycleProps {
  filterDestinations?: FilterValue[];
  filterUnitTypes?: FilterValue[];
  filterBrokerAgents?: FilterValue[];
  filterBrokerages?: FilterValue[];
}
export function DealLifeCycle(props: Readonly<DealLifeCycleProps>) {
  const userContext = useContext(USER_CONTEXT);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [leadsCount, setLeadsCount] = useState<AnalyticsLeadsCount>();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerage,
    handleSelectedBrokerage,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  const filtersParams = queryString.parse(window.location.search);

  useEffect(() => {
    let promise;
    if (userContext.user?.role === UserRole.BROKER) {
      promise = AnalyticsUtils.getAnalyticsLeadCount({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'broker_ids[]': selectedBrokerAgentsIds,
      });
    } else {
      promise = AnalyticsSalesManagerUtils.getAnalyticsLeadCountSalesManager({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'brokerage_company_ids[]': selectedBrokeragesIds,
      });
    }
    promise
      .then((res) => {
        setLeadsCount(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!leadsCount) {
    return <h1>No Leads Found</h1>;
  }

  const convertToCumulativePercentage = (count: number) => {
    if (count === 0 && leadsCount.submitted === 0) {
      return 0;
    }
    return +((count / leadsCount.submitted) * 100).toFixed(2);
  };

  const convertToNextStepPercentage = (count: number, prevCount: number) => {
    if ((count === 0 && prevCount === 0) || prevCount === 0) {
      return 0;
    }
    return +((count / prevCount) * 100).toFixed(2);
  };

  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <h3 className="title">
        {t('dealLifeCycle')}{' '}
        {userContext.user?.role === UserRole.BROKER ? (
          <AnalyticsFilter
            countriesFilter
            filterDestinations={props.filterDestinations}
            filterUnitTypes={props.filterUnitTypes}
            filterBrokerAgents={props.filterBrokerAgents}
            handleSelectBrokerAgents={handleSelectedBrokerAgents}
            handleSelectedDestinations={handleSelectedDestinations}
            handleSelectedUnitTypes={handleSelectedUnitTypes}
          />
        ) : null}
      </h3>
      <div className={`card ${styles['deal-lifecycle-wrapper']}`}>
        {userContext.user?.role === UserRole.SALES_MANAGER ? (
          <div className={styles['card-filters']}>
            <AnalyticsFilter
              buyerCountry
              unitSizeFilter
              filterDestinations={props.filterDestinations}
              filterUnitTypes={props.filterUnitTypes}
              filterBrokerages={props.filterBrokerages}
              handleSelectedDestinations={handleSelectedDestinations}
              handleSelectedUnitTypes={handleSelectedUnitTypes}
              handleSelectBrokerages={handleSelectedBrokerage}
            />
          </div>
        ) : null}
        <div className={`${styles['grid']} ${styles['headers']}`}>
          <p>LIFECYCLE STAGES</p>
          <p className={styles['count']}>COUNT OF DEALS</p>
          <p>
            NEXT-STEP CONVERSION{' '}
            <span className="info-msg">
              <img src={InfoIcon} alt="info-icon" />
              <p>
                {t(
                  'The percentage of contacts moving from that stage to the next.'
                )}
              </p>
            </span>
          </p>
          <p>
            CUMULATIVE CONVERSION{' '}
            <span className="info-msg">
              <img src={InfoIcon} alt="info-icon" />
              <p>
                {t(
                  'The percentage of contacts moving from the first stage to the next.'
                )}
              </p>
            </span>
          </p>
        </div>
        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Lead Submitted</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.submitted
            )}
            completedNumber={leadsCount.submitted.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {convertToNextStepPercentage(
                leadsCount.submitted,
                leadsCount.submitted
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>{convertToCumulativePercentage(leadsCount.submitted)}%</p>
          </div>
        </div>
        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Lead Verified</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.verifed
            )}
            completedNumber={leadsCount.verifed.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {convertToNextStepPercentage(
                leadsCount.verifed,
                leadsCount.submitted
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>{convertToCumulativePercentage(leadsCount.verifed)}%</p>
          </div>
        </div>
        {leadsCount.meeting !== 0 ? (
          <div className={`${styles['grid']} ${styles['content']}`}>
            <h4>Conducted Meeting</h4>
            <ProgressBar
              completedPercentage={convertToCumulativePercentage(
                leadsCount.meeting
              )}
              completedNumber={leadsCount.meeting.toString()}
            />
            <div className={styles['chevron']}>
              <p>
                {convertToNextStepPercentage(
                  leadsCount.meeting,
                  leadsCount.verifed
                )}
                %
              </p>
            </div>
            <div className={styles['chevron']}>
              <p>{convertToCumulativePercentage(leadsCount.meeting)}%</p>
            </div>
          </div>
        ) : null}

        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Reserved</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.reserved
            )}
            completedNumber={leadsCount.reserved.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {convertToNextStepPercentage(
                leadsCount.reserved,
                leadsCount.verifed
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>{convertToCumulativePercentage(leadsCount.reserved)}%</p>
          </div>
        </div>
        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Deal Closed</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.closed
            )}
            completedNumber={leadsCount.closed.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {' '}
              {convertToNextStepPercentage(
                leadsCount.closed,
                leadsCount.reserved
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>{convertToCumulativePercentage(leadsCount.closed)}%</p>
          </div>
        </div>

        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Submitted Sales Invoice</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.invoice_submitted
            )}
            completedNumber={leadsCount.invoice_submitted.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {' '}
              {convertToNextStepPercentage(
                leadsCount.invoice_submitted,
                leadsCount.closed
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>
              {convertToCumulativePercentage(leadsCount.invoice_submitted)}%
            </p>
          </div>
        </div>

        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Sales Invoice Approved</h4>
          <ProgressBar
            completedPercentage={convertToCumulativePercentage(
              leadsCount.invoice_approved
            )}
            completedNumber={leadsCount.invoice_approved.toString()}
          />
          <div className={styles['chevron']}>
            <p>
              {' '}
              {convertToNextStepPercentage(
                leadsCount.invoice_approved,
                leadsCount.invoice_submitted
              )}
              %
            </p>
          </div>
          <div className={styles['chevron']}>
            <p>{convertToCumulativePercentage(leadsCount.invoice_approved)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DealLifeCycle;

/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import styles from './image-gallery.module.scss';
import { v4 as uuidv4 } from 'uuid';

export interface ImageGalleryProps {
  images: string[];
}

export function ImageGallery(props: Readonly<ImageGalleryProps>) {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  /**
   * handles replacing an image in the gallery with the large image displayed when clicked.
   *
   * @param {number} index
   * @returns
   */
  const enlargeImgHandler = (index: number) => {
    if (index === 0) {
      return;
    }
    const galleryImages = [...images];
    galleryImages.splice(0, 1, images[index]);
    galleryImages.splice(index, 1, images[0]);
    setImages(galleryImages);
  };

  return (
    <div className={styles['wrapper']}>
      {images?.map((img, index) => (
        <img
          key={uuidv4()}
          src={img}
          alt=""
          className={styles['image']}
          onClick={() => enlargeImgHandler(index)}
          onKeyDown={() => enlargeImgHandler(index)}
          loading="lazy"
        />
      ))}
    </div>
  );
}

export default ImageGallery;

import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import styles from '../../pages/my-deals/my-deals.module.scss';
import bulletIcon from '../../assets/icons/bullet-point.png';
import CircularArrow from '../../components/common/circular-arrow/circular-arrow';
import {
  DealInterface,
  DealStatusEnum,
} from '../../definitions/interfaces/deals.interface';

interface DealCardProps {
  deal: DealInterface;
  status:
    | DealStatusEnum.LEAD_SUBMITTED
    | DealStatusEnum.LEAD_VERIFIED
    | DealStatusEnum.DEAL_CLOSED
    | DealStatusEnum.SUBMITTED_INVOICE
    | DealStatusEnum.INVOICE_APPROVED
    | DealStatusEnum.LEAD_RESERVED
    | DealStatusEnum
    | null
    | undefined;
  duplicateDeal?: boolean;
  pastDeal?: boolean;
}

export function DealCard(props: Readonly<DealCardProps>) {
  const bedroomsInterestInUnit =
    props.deal.interested_in_unit_details &&
    (props.deal.interested_in_unit_details.bedrooms
      ? `+  ${props.deal.interested_in_unit_details.bedrooms} bedrooms`
      : null);

  const dealInterestedInUnit = props.deal.interested_in_unit_details
    ? `${props.deal.interested_in_unit_details.name} ${props.deal.interested_in_unit_details.unit_type.name} ${bedroomsInterestInUnit}`
    : 'no unit of interest';

  const bedroomsInterestInSaleUnit =
    props.deal.sales?.length &&
    props.deal.sales[0].unit &&
    (props.deal.sales[0].unit.bedrooms
      ? `+  ${props.deal.sales[0].unit.bedrooms} bedrooms`
      : null);

  // const dealUnit =
  //   props.deal.sales?.length && props.deal.sales[0].unit
  //     ? `${props.deal.sales[0].unit.name} ${props.deal.sales[0].unit.unit_type.name} ${bedroomsInterestInSaleUnit}`
  //     : dealInterestedInUnit;

  return (
    <Link
      to={ROUTES['DealDetails'].getPath?.(
        props.duplicateDeal
          ? props.deal.lead_history_source_id
          : props.deal.lead_source_id,
        props.deal.country_id,
        props.duplicateDeal ? '1' : '0',
        props.deal.broker.id
      )}
      className={`card ${styles['deal']}`}
    >
      <div className={styles['info']}>
        <p className={styles['label']}>
          Interested Prospect{' '}
          {props.duplicateDeal ? <span>(duplicated deal)</span> : null}
        </p>
        <div className={styles['value']}>{props.deal.name}</div>
      </div>
      <div className={styles['info']}>
        <p className={styles['label']}>Phone Number</p>
        <div className={styles['value']}>{props.deal.phone}</div>
      </div>
      <div className={styles['info']}>
        <p className={styles['label']}>Deal Status</p>
        <div className={styles['value']}>
          <img src={bulletIcon} alt="" role="presentation" />
          {props.status?.replace(/_/g, ' ')}
        </div>
      </div>
      <CircularArrow />
    </Link>
  );
}

export default DealCard;

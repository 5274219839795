import { useState } from 'react';
import { UnitMasterPlan } from '@orascom/api-interfaces';
import styles from './unit-plan.module.scss';
import { useTranslation } from 'react-i18next';

export interface UnitPlanProps {
  title: string;
  plans: UnitMasterPlan[];
}

export function UnitPlan(props: Readonly<UnitPlanProps>) {
  const [planIndex, setPlanIndex] = useState(0);

  const { t } = useTranslation();

  const { title, plans } = props;

  return (
    <div className={`${styles['wrapper']} card`}>
      <h3>{title}</h3>
      {plans.length > 1 && (
        <div className={styles['tabs']}>
          {plans.map((plan, index) => (
            <button
              key={plan.id}
              onClick={() => setPlanIndex(index)}
              className={`${styles['tab']} ${
                planIndex === index && styles['tab--active']
              }`}
            >
              {plan.title}
            </button>
          ))}
        </div>
      )}
      <div className={styles['image']}>
        <img
          src={plans[planIndex].image}
          alt={plans[planIndex].title}
          loading="lazy"
        />
      </div>
      <div className={styles['download']}>
        <a
          href={plans[planIndex].image}
          download
          target="_blank"
          rel="noreferrer"
          className="anchor"
        >
          {`${t('download')} ${title}`}
        </a>
      </div>
    </div>
  );
}

export default UnitPlan;

import { DateRange, PeriodType } from '@orascom/api-interfaces';
import { downloadAnalyticsReport } from '@orascom/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export const useAnalyticsPeriodFilter = () => {
  const navigate = useNavigate();

  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const [selectedDuration, setSelectedDuration] =
    useState<PeriodType>('lastyear');
  const [downloadingReport, setDownloadingReport] = useState(false);
  const currentParams = new URLSearchParams(window.location.search);

  const handleSetSelectedDuration = (duration: PeriodType) => {
    setSelectedDuration(duration);
  };
  const handleShowPeriodModal = () => {
    setShowPeriodModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setShowPeriodModal(false);
    document.body.style.overflow = '';
    navigate({ search: currentParams.toString() });
  };
  const handleDownloadReport = () => {
    downloadAnalyticsReport('sales-managers-commissions', setDownloadingReport);
  };

  const lastYearStartDate = new Date();
  lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);

  const [value, setValue] = useState<DateRange>({
    startDate: lastYearStartDate,
    endDate: new Date(),
  });

  const filtersParams = queryString.parse(window.location.search);

  return {
    showPeriodModal,
    selectedDuration,
    downloadingReport,
    currentParams,
    handleSetSelectedDuration,
    handleShowPeriodModal,
    handleCloseModal,
    handleDownloadReport,
    value,
    setValue,
    filtersParams,
  };
};

export default useAnalyticsPeriodFilter;

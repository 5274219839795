/**
 * @author Salma Hefnawy
 * @date 2022-10-02
 * @description implementation of units related API calls.
 * @filename units.ts
 */
import { Network } from './network';
import {
  PaginationPayload,
  OptionValue,
  Payload,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms as UnitPaymentTermsInterface,
  UnitInterface,
  UnitCompareInterface,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';

/**
 * implement unit related calls.
 */
export class Unit {
  public static getUnits(
    searchParams?: URLSearchParams | string
  ): Promise<Payload<PaginationPayload<UnitInterface[]>[]>> {
    const baseUrl = process.env['NX__BROKER_API_ENDPOINT'];
    const unitsEndpoint = ENDPOINTS.getUnits.url();

    const url = new URL(`${baseUrl}${unitsEndpoint}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    return Network.fetch(url.href, {
      method: ENDPOINTS.getUnits.method,
    });
  }

  public static getUnitDetails(
    unitId: number
  ): Promise<Payload<UnitDetailsInterface>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getUnitDetails.url(
        unitId
      )}`,
      {
        method: ENDPOINTS.getUnitDetails.method,
      }
    );
  }

  public static getCompareUnits(
    unitsIds: number[]
  ): Promise<Payload<UnitCompareInterface[]>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.compareUnits.path}`
    );
    for (const index in unitsIds) {
      url.searchParams.append(`unit_ids[${index}]`, `${unitsIds[index]}`);
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.compareUnits.method,
    });
  }

  public static getUnitPaymentTerms(
    unitId: number
  ): Promise<Payload<Payload<UnitPaymentTermsInterface[]>>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getUnitPaymentTerms.url(unitId)}`,
      {
        method: ENDPOINTS.getUnitDetails.method,
      }
    );
  }

  public static geSavedUnits(
    page: number,
    perPage: number,
    destinations?: OptionValue[]
  ): Promise<Payload<PaginationPayload<UnitInterface[]>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getSavedUnits.url}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));
    if (destinations?.length) {
      destinations.forEach((d, i) => {
        url.searchParams.append(`destinations[${i}]`, String(d.value));
      });
    }
    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSavedUnits.method,
    });
  }

  public static addSavedUnit(unitId: number): Promise<Payload<void>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.addSavedUnit.url(
        unitId
      )}`,
      {
        method: ENDPOINTS.addSavedUnit.method,
      }
    );
  }

  public static deleteSavedUnit(unitId: number): Promise<Payload<void>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.deleteSavedUnit.url(
        unitId
      )}`,
      {
        method: ENDPOINTS.deleteSavedUnit.method,
      }
    );
  }

  public static downloadOmanSalesOffer(unitId: number): Promise<any> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.downloadOmanSalesOffer.url(
        unitId
      )}`
    );

    return fetch(url.toString(), {
      method: ENDPOINTS.downloadOmanSalesOffer.method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          process.env['NX_BROKER_ACCESS_TOKEN_KEY'] as string
        )}`,
      },
    }).then((res) => res.blob());
  }
}

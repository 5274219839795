import { useContext, useEffect } from 'react';
import { Updates as UpdatesUtils } from '../../utils/updates';
import { Loader } from '@orascom/common-components';
import updatesIcon from '../../assets/icons/updates.svg';
import { notificationTimeCalculator } from '@orascom/utils';
import { useTranslation } from 'react-i18next';
import styles from './updates.module.scss';
import { NOTIFICATIONS_CONTEXT } from '@orascom/broker-sales-man-common-components';

export function Updates() {
  const { t } = useTranslation();
  const { notifications, setIsRead, isLoadingUpdates } = useContext(
    NOTIFICATIONS_CONTEXT
  );

  useEffect(() => {
    let isReadNotifications = true;
    notifications?.every((n) => {
      if (!n.is_read) {
        isReadNotifications = false;
        return false;
      }
      return true;
    });
    if (isReadNotifications) {
      return;
    }

    const notificationsIds = notifications?.map((n) => n.id);
    if (notificationsIds && Boolean(notificationsIds?.length)) {
      UpdatesUtils.readNotifications(notificationsIds)
        .then(() => setIsRead?.(true))
        .catch((err) => console.error(err));
    }
  }, [notifications]);

  if (isLoadingUpdates) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('updates')}</h1>
      <div className={styles['flex']}>
        {Boolean(notifications?.length) &&
          notifications?.map((u) => (
            <div key={u.id} className={`card ${styles['update']}`}>
              <div className={styles['info']}>
                <div className={styles['icon']}>
                  <img src={updatesIcon} alt="" role="presentation" />
                </div>
                <p className={styles['content']}>{u.message}</p>
              </div>
              <div className={styles['time']}>
                {notificationTimeCalculator(u.created_at)}
              </div>
            </div>
          ))}
        {!notifications?.length && <h3>{t('noNotificationsToDisplay')}</h3>}
      </div>
    </div>
  );
}

export default Updates;

import { OptionValue } from '@orascom/api-interfaces';
import { useState } from 'react';
import queryString from 'query-string';
import {
  getTimeStampsForAnalytics,
  parseFiltersInUrl,
} from '../utils/analytics';

export const useAnalyticsFilters = () => {
  const filtersParams = queryString.parse(window.location.search);
  const { timestampStart, timestampEnd } = getTimeStampsForAnalytics();
  const startDateArg = filtersParams['start']?.toString() ?? timestampStart;
  const endDateArg = filtersParams['end']?.toString() ?? timestampEnd;

  const [selectedDestinations, setSelectedDestinations] = useState<
    OptionValue[]
  >([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<OptionValue[]>([]);

  const [selectedBrokerAgents, setSelectedBrokerAgents] = useState<
    OptionValue[]
  >([]);
  const [selectedBrokerage, setSelectedBrokerage] = useState<OptionValue[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<OptionValue[]>([]);
  const [selectedSalesManager, setSelectedSalesManager] = useState<
    OptionValue[]
  >([]);

  const handleSelectedDestinations = (value: OptionValue[]) => {
    setSelectedDestinations(value);
  };

  const handleSelectedUnitTypes = (value: OptionValue[]) => {
    setSelectedUnitTypes(value);
  };

  const handleSelectedBrokerAgents = (value: OptionValue[]) => {
    setSelectedBrokerAgents(value);
  };

  const handleSelectedBrokerage = (value: OptionValue[]) => {
    setSelectedBrokerage(value);
  };
  const handleSelectedStatuses = (value: OptionValue[]) => {
    setSelectedStatuses(value);
  };

  const handleSelectedSalesManagers = (value: OptionValue[]) => {
    setSelectedSalesManager(value);
  };

  const selectedDestinationsIds = parseFiltersInUrl(selectedDestinations);
  const selectedUnitTypesIds = parseFiltersInUrl(selectedUnitTypes);
  const selectedBrokerAgentsIds = parseFiltersInUrl(selectedBrokerAgents);
  const selectedBrokeragesIds = parseFiltersInUrl(selectedBrokerage);
  const selectedStatusesIds = parseFiltersInUrl(selectedStatuses);
  const selectedSalesManagersIds = parseFiltersInUrl(selectedSalesManager);

  return {
    selectedDestinations,
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
    selectedStatuses,
    selectedSalesManager,
    handleSelectedDestinations,
    handleSelectedUnitTypes,
    handleSelectedBrokerAgents,
    handleSelectedBrokerage,
    handleSelectedStatuses,
    handleSelectedSalesManagers,
    selectedDestinationsIds,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    selectedStatusesIds,
    selectedSalesManagersIds,
    startDateArg,
    endDateArg,
    filtersParams,
  };
};

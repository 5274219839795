import { ReactNode, useEffect, useMemo, useState } from 'react';
import CompareUnitsContext from './compare-units-context';

export const BROKER_COMPARE_UNITS_LIMIT = 3;
const BROKER_SESSION_STORAGE_KEY = 'broker_compare_units';

const CompareUnitsProvider = ({ children }: { children: ReactNode }) => {
  const [compareUnitsIds, setCompareUnitsIds] = useState<number[] | undefined>(
    undefined
  );
  const [showExceedLimitError, setShowExceedLimitError] =
    useState<boolean>(false);

  useEffect(() => {
    const compareUnitsJson = sessionStorage.getItem(BROKER_SESSION_STORAGE_KEY);

    if (compareUnitsJson) {
      const units = JSON.parse(compareUnitsJson);
      const unitsIds: number[] = units.map((unitId: string) => Number(unitId));

      setCompareUnitsIds(unitsIds);
    } else {
      setCompareUnitsIds([]);
    }
  }, []);

  const addUnitToCompare = (unitId: number) => {
    if (!compareUnitsIds) return;

    // check if exceed limit
    if (compareUnitsIds.length >= BROKER_COMPARE_UNITS_LIMIT) {
      setShowExceedLimitError(true);
      setTimeout(function () {
        setShowExceedLimitError(false);
      }, 3000);
      return;
    }
    sessionStorage.setItem(
      BROKER_SESSION_STORAGE_KEY,
      JSON.stringify([...compareUnitsIds, unitId])
    );
    setCompareUnitsIds([...compareUnitsIds, unitId]);
  };

  const removeUnitFromCompare = (unitId: number) => {
    if (!compareUnitsIds) return;
    const unitToRemove = compareUnitsIds.find((unit) => unit === unitId);

    if (unitToRemove) {
      sessionStorage.setItem(
        BROKER_SESSION_STORAGE_KEY,
        JSON.stringify(compareUnitsIds.filter((unit) => unit !== unitId))
      );

      setCompareUnitsIds(compareUnitsIds.filter((unit) => unit !== unitId));
    }
  };

  const clearCompareUnits = () => {
    sessionStorage.removeItem(BROKER_SESSION_STORAGE_KEY);
    setCompareUnitsIds([]);
  };

  const CompareUnitsValue = useMemo(
    () => ({
      compareUnitsIds,
      addUnitToCompare,
      removeUnitFromCompare,
      showExceedLimitError,
      clearCompareUnits,
    }),
    [
      compareUnitsIds,
      addUnitToCompare,
      removeUnitFromCompare,
      showExceedLimitError,
      clearCompareUnits,
    ]
  );

  return (
    <CompareUnitsContext.Provider value={CompareUnitsValue}>
      {children}
    </CompareUnitsContext.Provider>
  );
};

export default CompareUnitsProvider;

import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../api/routes';
import { User as UserUtils } from '../utils/user';
import { Loader, ScrollToTop } from '@orascom/common-components';
import Sidebar from '../components/common/sidebar/sidebar';
import {
  Header,
  NOTIFICATIONS_CONTEXT,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { Updates as UpdatesUtils } from '../utils/updates';
import styles from './app.module.scss';
import {
  UpdatesInterface,
  UserInterface,
  UserRole,
} from '@orascom/api-interfaces';
// eslint-disable-next-line @nx/enforce-module-boundaries
import CompareUnitsProvider from 'libs/broker-sales-man-common-components/src/contexts/compare-units-provider';
import { CurrencyContextProvider } from '@orascom/utils';

export function App() {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [notifications, setNotifications] = useState<UpdatesInterface[]>([]);
  const [isUpdatesRead, setIsUpdatesRead] = useState(true);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [isLoadingUpdates, setIsLoadingUpdates] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      const userPermitted =
        user?.role === route.role || route.role === UserRole.ALL;

      if ((!route.public && !user) || !userPermitted) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  useEffect(() => {
    if (!user) {
      if (
        localStorage.getItem(
          process.env['NX_BROKER_ACCESS_TOKEN_KEY'] as string
        )
      ) {
        UserUtils.getUserData()
          .then((res) => {
            setUser(res.data.user);
          })
          .catch(() => {
            if (location.pathname !== ROUTES['ResetPassword'].path) {
              navigate(ROUTES['Login'].path);
            }
          })
          .finally(() => setLoadingUser(false));
      } else {
        setLoadingUser(false);
        if (location.pathname !== ROUTES['ResetPassword'].path) {
          navigate(ROUTES['Login'].path);
        }
      }
    } else {
      setLoadingUser(false);

      if (user && user.role === UserRole.BROKER) {
        UpdatesUtils.getUpdates()
          .then((response) => {
            setNotifications(response);

            response.every((n) => {
              if (!n.is_read) {
                setIsUpdatesRead(false);
                return false;
              }
              return true;
            });
          })
          .catch((err) => console.error(err))
          .finally(() => setIsLoadingUpdates(false));
      }
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
    }),
    [user, loadingUser]
  );
  const notificationContextValue = useMemo(
    () => ({
      notifications,
      isRead: isUpdatesRead,
      setIsRead: setIsUpdatesRead,
      isLoadingUpdates,
    }),
    [notifications, isUpdatesRead, isLoadingUpdates]
  );

  // This is necessary because the current implementation forces one layout,
  // and we need to ensure the NotFound route doesn't render the sidebar,
  // without breaking the existing style logic.
  const isNotFoundRoute = !Object.values(ROUTES).some(
    ({ path }) =>
      path === location.pathname ||
      (path.includes(':') &&
        new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(
          location.pathname
        ))
  );

  if (loadingUser && !isNotFoundRoute) {
    return <Loader />;
  }

  const showSidebar = !isNotFoundRoute && user;
  return (
    <USER_CONTEXT.Provider value={userContextValue}>
      <NOTIFICATIONS_CONTEXT.Provider value={notificationContextValue}>
        <CompareUnitsProvider>
          <CurrencyContextProvider>
            <main
              className={`${user ? 'container container--flex' : 'container'}`}
            >
              {showSidebar && <Sidebar />}
              <ScrollToTop />
              <section className={styles['side-section']}>
                {showSidebar && (
                  <Header notificationPath={ROUTES['Notifications'].path} />
                )}
                <Routes>{availableRoutes}</Routes>
              </section>
            </main>
          </CurrencyContextProvider>
        </CompareUnitsProvider>
      </NOTIFICATIONS_CONTEXT.Provider>
    </USER_CONTEXT.Provider>
  );
}

export default App;

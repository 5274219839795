import { UpdatesInterface } from '@orascom/api-interfaces';
import React from 'react';


export const NOTIFICATIONS_CONTEXT: React.Context<{
  /**
   * Array containing all the notifications
   *
   * @type {UpdatesInterface[]}
   */
  notifications?: UpdatesInterface[];

  /**
   * Boolean indicating if there are unread notifications
   *
   * @type {boolean}
   */
  isRead?: boolean;

  setIsRead?: (arg: boolean) => void;

  /**
   * Boolean indicating fetching notifications
   *
   * @type {boolean}
   */
  isLoadingUpdates?: boolean;
}> = React.createContext({});

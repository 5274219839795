/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Accordion from 'libs/common-components/src/lib/accordion/accordion';
import styles from './broker-agent-ranking.module.scss';
import stockIcon from '../../assets/icons/stock.svg';

import { AnalyticsCompanyRanking } from '../../definitions/interfaces/analytics';
import { FilterValue } from '@orascom/api-interfaces';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import {
  AnalyticsFilter,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';

interface BrokerAgentRankingProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
}
export function BrokerAgentRanking(props: Readonly<BrokerAgentRankingProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const filtersParams = queryString.parse(window.location.search);

  const [companyRanking, setCompanyRanking] =
    useState<AnalyticsCompanyRanking>();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    AnalyticsUtils.getAnalyticsCompanyRanking({
      from: startDateArg,
      to: endDateArg,
      'destination_ids[]': selectedDestinationsIds,
      'unit_type_ids[]': selectedUnitTypesIds,
    })

      .then((res) => {
        setCompanyRanking(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
  ]);

  if (loading) {
    return <Loader />;
  }

  const brokerRankingMarkup = (
    <div>
      <div>
        <div
          className={`${styles['broker-comissionsRankings-wrapper']} broker-cards-grid`}
        >
          <div className="overview-card">
            <div className="icon icon--gold">
              <p>#{companyRanking?.broker_company_rank}</p>
            </div>
            <div className="content--rank">
              <h2>
                {t('outOf')}{' '}
                <span>{companyRanking?.company_brokers_count}</span>{' '}
                {t('brokers')}
              </h2>
            </div>
          </div>
          {companyRanking && companyRanking?.broker_company_rank > 2 ? (
            <div className="overview-card">
              <div className="icon">
                <img src={stockIcon} alt="" role="presentation" />
              </div>
              <div className="content">
                <h2>
                  {t('earn')}{' '}
                  {companyRanking?.diff_higher_rank.toLocaleString()}{' '}
                  {companyRanking?.currency}
                </h2>
                <p>
                  {t('toReachThe')} <span>{companyRanking?.higher_rank}</span>
                </p>
              </div>
            </div>
          ) : null}
          <div className="overview-card">
            <div className="icon">
              <img src={stockIcon} alt="" role="presentation" />
            </div>
            <div className="content">
              <h2>
                {t('earn')} {companyRanking?.diff_first_rank.toLocaleString()}{' '}
                {companyRanking?.currency}
              </h2>
              <p>
                {t('toReachThe')} <span>1st Place</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <Accordion
        initialOpenState
        analyticsFilter
        title={
          <h3 className="title">
            {t('rankings')}
            <AnalyticsFilter
              filterDestinations={props.filterDestinations}
              filterUnitTypes={props.filterUnitTypes}
              handleSelectedDestinations={handleSelectedDestinations}
              handleSelectedUnitTypes={handleSelectedUnitTypes}
            />
          </h3>
        }
      >
        <div className="card">{brokerRankingMarkup}</div>
      </Accordion>
    </div>
  );
}

export default BrokerAgentRanking;

/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommisionsInsights from '../../components/commisions-insights/commisions-insights';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import CommissionsRanking from '../../components/commissions-ranking/commissions-ranking';
import { FilterValue } from '@orascom/api-interfaces';

import {
  AnalyticsPeriodFilter,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';

export function SalesManagerCommissions() {
  const { t } = useTranslation();

  const { downloadingReport } = useAnalyticsPeriodFilter();

  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);
  const [brokerageCompanies, setBrokerageCompanies] = useState<FilterValue[]>(
    []
  );

  useEffect(() => {
    Promise.all([
      AnalyticsSalesManagerUtils.getAnalyticsFilterDestinationsSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterUnitTypeSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterBrokerageSalesManager(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
        setBrokerageCompanies(res[2]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="analytics">
      <div className="analytics__title">
        <h1>{t('commissions')}</h1>
      </div>
      <AnalyticsPeriodFilter portal="broker" />

      <div id="analytics">
        <CommisionsInsights
          filterBrokerAgents={brokerageCompanies}
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          downloadingReport={downloadingReport}
        />
        <CommissionsRanking filterBrokerages={brokerageCompanies} />
      </div>
    </div>
  );
}

export default SalesManagerCommissions;

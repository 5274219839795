import React from 'react';
import { UnitDetails as UnitDetailsInterface } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './unit-specs.module.scss';
import {
  MONTENEGRO_UNITS_BATHROOMS,
  MONTENEGRO_UNITS_FLOOR_NUMBER,
} from '../../definitions/consts/units';

export interface UnitSpecsProps {
  unit: UnitDetailsInterface;
}

export const UnitSpecs = React.forwardRef(
  (props: UnitSpecsProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();

    const { unit } = props;

    let unitFloorNumber: number | string = unit.floor_number;
    let unitBathroomsNumber: number | string = unit.bathrooms;

    if (
      unitFloorNumber &&
      unit.project?.destination?.country?.slug === 'montenegro'
    ) {
      unitFloorNumber = MONTENEGRO_UNITS_FLOOR_NUMBER[unitFloorNumber];
    }

    if (
      unitBathroomsNumber &&
      unit.project?.destination?.country?.slug === 'montenegro'
    ) {
      unitBathroomsNumber = MONTENEGRO_UNITS_BATHROOMS[unitBathroomsNumber];
    }

    return (
      <div className={`${styles['wrapper']} card`} ref={ref}>
        <h3>{t('fullUnitSpecs')}</h3>
        <table>
          <tbody>
            {unit.name ? (
              <tr>
                <td>{t('unitName')}</td>
                <td>{unit.name}</td>
              </tr>
            ) : null}
            {unit.project.destination.name ? (
              <tr>
                <td>{t('destination')}</td>
                <td>{unit.project.destination.name}</td>
              </tr>
            ) : null}
            {unit.unit_type.name ? (
              <tr>
                <td>{t('unitType')}</td>
                <td>{unit.unit_type.name}</td>
              </tr>
            ) : null}
            {unit.construction_status ? (
              <tr>
                <td>{t('buildingStatus')}</td>
                <td>
                  {unit.construction_status === 'off_plan'
                    ? t('offPlan')
                    : unit.construction_status?.toString().replace(/_/g, ' ')}
                </td>
              </tr>
            ) : null}
            {unit.built_up_area ? (
              <tr>
                <td>{t('builtUpArea')}</td>
                <td>
                  {unit.built_up_area} {t('mSquared')}
                </td>
              </tr>
            ) : null}
            {unit.garden_area ? (
              <tr>
                <td>{t('gardenAreaSqM')}</td>
                <td>
                  {unit.garden_area} {t('mSquared')}
                </td>
              </tr>
            ) : null}
            {unit.total_area ? (
              <tr>
                <td>{t('totalLandAreaSqM')}</td>
                <td>
                  {unit.total_area} {t('mSquared')}
                </td>
              </tr>
            ) : null}
            {unit.bedrooms ? (
              <tr>
                <td>{t('numberOfBedrooms')}</td>
                <td>{unit.bedrooms}</td>
              </tr>
            ) : null}
            {unitBathroomsNumber ? (
              <tr>
                <td>{t('numberOfBathrooms')}</td>
                <td>{unitBathroomsNumber}</td>
              </tr>
            ) : null}
            {unit.finishing_status ? (
              <tr>
                <td>{t('finishingOption')}</td>
                <td>{unit.finishing_status}</td>
              </tr>
            ) : null}
            {unitFloorNumber ? (
              <tr>
                <td>{t('floorNumber')}</td>
                <td>{unitFloorNumber}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
);

export default UnitSpecs;

/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import { FilterValue } from '@orascom/api-interfaces';
import SalesManagerBrokerRanking from '../../components/sales-manager-broker-ranking/sales-manager-broker-ranking';
import SalesManagerRankingTable from '../../components/sales-manager-ranking-table/sales-manager-ranking-table';
import SalesManagerBrokerageRanking from '../../components/sales-manager-brokerage-ranking/sales-manager-brokerage-ranking';
import {
  AnalyticsFilterSalesManagers,
  AnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';

function SalesManagerRanking() {
  const { t } = useTranslation();

  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);
  const [brokerageCompanies, setBrokerageCompanies] = useState<FilterValue[]>(
    []
  );
  const [filterSalesManagers, setFilterSalesManagers] = useState<
    AnalyticsFilterSalesManagers[]
  >([]);

  useEffect(() => {
    Promise.all([
      AnalyticsSalesManagerUtils.getAnalyticsFilterDestinationsSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterUnitTypeSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterBrokerageSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsSFilterSalesManagers(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
        setBrokerageCompanies(res[2]);
        setFilterSalesManagers(res[3]);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="analytics">
      <div className="analytics__title">
        <h1>{t('rankings')}</h1>
      </div>
      <AnalyticsPeriodFilter portal="broker" />

      <div id="analytics">
        <SalesManagerBrokerageRanking
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
        />
        <SalesManagerRankingTable
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          filterBrokerages={brokerageCompanies}
          filterSalesManagers={filterSalesManagers}
        />
        <SalesManagerBrokerRanking
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          filterBrokerages={brokerageCompanies}
        />
      </div>
    </div>
  );
}

export default SalesManagerRanking;

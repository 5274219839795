import { Network } from './network';
import { Payload, UpdatesInterface } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';

export class Updates {
  public static getUpdates(): Promise<Payload<UpdatesInterface[]>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getRecentUpdates.url}`,
      {
        method: ENDPOINTS.getRecentUpdates.method,
      }
    );
  }

  public static readNotifications(
    notificationsIds: number[],
    isRead = 1
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.readNotifications.url}`,
      {
        method: ENDPOINTS.readNotifications.method,
        body: JSON.stringify({
          notification_ids: notificationsIds,
          is_read: isRead,
        }),
      }
    );
  }
}

/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import Accordion from 'libs/common-components/src/lib/accordion/accordion';
import styles from './revenue-insights.module.scss';
import unitsRevenue from '../../assets/icons/units-revenue.svg';
import totalUnitsSold from '../../assets/icons/total-units-sold.svg';
import { PieChart } from 'react-minimal-pie-chart';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { useContext, useEffect, useState } from 'react';
import {
  AnalyticsRevenueInsights,
  AnalyticsRevenueInsightsSalesManager,
} from '../../definitions/interfaces/analytics';
import { FilterValue, UserRole } from '@orascom/api-interfaces';
import { Loader } from '@orascom/common-components';
import queryString from 'query-string';
import { AnalyticsSalesManager as AnalyticsSalesManagerApi } from '../../utils/analytics-sales-manager';
import {
  AnalyticsFilter,
  useAnalyticsFilters,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';

interface RevenueInsightsProps {
  filterDestinations?: FilterValue[];
  filterUnitTypes?: FilterValue[];
  filterBrokerAgents?: FilterValue[];
  filterBrokerages?: FilterValue[];
  downloadingReport?: boolean;
}

export function RevenueInsights(props: Readonly<RevenueInsightsProps>) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const [loading, setLoading] = useState(true);
  const [revenueInsights, setRevenueInsights] =
    useState<AnalyticsRevenueInsights>();
  const [revenueInsightsSalesManager, setRevenueInsightsSalesManager] =
    useState<AnalyticsRevenueInsightsSalesManager>();
  const filtersParams = queryString.parse(window.location.search);
  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerage,
    handleSelectedBrokerage,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  const TotalValue =
    userContext.user?.role === UserRole.SALES_MANAGER
      ? revenueInsightsSalesManager?.value.toLocaleString()
      : revenueInsights?.total_value.toLocaleString();

  const currency =
    userContext.user?.role === UserRole.SALES_MANAGER
      ? revenueInsightsSalesManager?.currency
      : revenueInsights?.currency;

  const totalSoldUnits =
    userContext.user?.role === UserRole.SALES_MANAGER
      ? revenueInsightsSalesManager?.deals
      : revenueInsights?.total_number_of_deals;

  useEffect(() => {
    if (userContext.user?.role === UserRole.SALES_MANAGER) {
      AnalyticsSalesManagerApi.getAnalyticsRevenueInsightsSalesManager({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'brokerage_company_ids[]': selectedBrokeragesIds,
      })
        .then((res) => {
          setRevenueInsightsSalesManager(res);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      AnalyticsUtils.getAnalyticsRevenueInsights({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'broker_ids[]': selectedBrokerAgentsIds,
      })
        .then((res) => {
          setRevenueInsights(res);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
  ]);
  if (loading) {
    return <Loader />;
  }

  const revenueInsightsBody = (
    <div className="card">
      {userContext.user?.role === UserRole.SALES_MANAGER ? (
        <div className={styles['card-filters']}>
          <AnalyticsFilter
            filterDestinations={props.filterDestinations}
            filterUnitTypes={props.filterUnitTypes}
            filterBrokerages={props.filterBrokerages}
            handleSelectedDestinations={handleSelectedDestinations}
            handleSelectedUnitTypes={handleSelectedUnitTypes}
            handleSelectBrokerages={handleSelectedBrokerage}
          />
        </div>
      ) : null}
      <div
        className={`${styles['revenue-insights-wrapper']} broker-cards-grid`}
      >
        <div className="overview-card">
          <div className="icon">
            <img src={totalUnitsSold} alt="" role="presentation" />
          </div>
          <div className="content">
            <p>{t('totalSoldUnits')}</p>
            <h2>{totalSoldUnits} Units</h2>
          </div>
        </div>
        <div className="overview-card">
          <div className="icon">
            <img src={unitsRevenue} alt="" role="presentation" />
          </div>
          <div className="content">
            <p>{t('totalUnitsRevenue')}</p>
            <h2>
              {TotalValue} {currency}
            </h2>
          </div>
        </div>
        {userContext.user?.role === UserRole.SALES_MANAGER ? (
          <div className="overview-card">
            <div className="icon">
              <img src={unitsRevenue} alt="" role="presentation" />
            </div>
            <div className="content">
              <p>{t('noOfCancelledDeals')}</p>
              <h2>{revenueInsightsSalesManager?.canceled_deals} Deals</h2>
            </div>
          </div>
        ) : null}
      </div>
      {userContext.user?.role === UserRole.BROKER ? (
        <div className="broker-cards-grid">
          {revenueInsights && (
            <div className={`${styles['pie-card']} card`}>
              <h3 className={styles['title']}>
                {t('totalNoOfDealsVsClosedDeals')}
              </h3>
              <div className={styles['content']}>
                <PieChart
                  data={[
                    {
                      title: t('closedDeals'),
                      value: revenueInsights?.total_number_of_closed_deals,
                      color: '#B4905C',
                    },
                    {
                      title: t('remaining'),
                      value:
                        revenueInsights?.total_number_of_deals -
                        revenueInsights?.total_number_of_closed_deals,
                      color: '#ffffff',
                    },
                  ]}
                  style={{
                    width: '114px',
                    height: '114px',
                    border: '1px solid rgba(181, 149, 102, 0.3)',
                    padding: props.downloadingReport ? '0' : '10px',
                    borderRadius: '50%',
                    boxShadow: '0px 10px 17px -5px rgba(18, 43, 94, 0.25)',
                  }}
                  startAngle={270}
                  animate
                />
                <div>
                  <h2>
                    {Math.round(
                      (revenueInsights.total_number_of_closed_deals /
                        revenueInsights.total_number_of_deals) *
                        100
                    )}
                    %
                  </h2>
                  <h5>
                    {revenueInsights.total_number_of_closed_deals}{' '}
                    {t('closedDeals')}
                  </h5>
                  <span>
                    / {revenueInsights.total_number_of_deals} {t('totalDeals')}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );

  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      {userContext.user?.role === UserRole.BROKER ? (
        <Accordion
          analyticsFilter
          initialOpenState
          title={
            <h3 className="title">
              {t('revenueInsights')}{' '}
              <AnalyticsFilter
                filterDestinations={props.filterDestinations}
                filterUnitTypes={props.filterUnitTypes}
                filterBrokerAgents={props.filterBrokerAgents}
                handleSelectBrokerAgents={handleSelectedBrokerAgents}
                handleSelectedDestinations={handleSelectedDestinations}
                handleSelectedUnitTypes={handleSelectedUnitTypes}
              />
            </h3>
          }
        >
          {revenueInsightsBody}
        </Accordion>
      ) : (
        <>
          <h3 className="title">{t('revenueInsights')} </h3>
          {revenueInsightsBody}
        </>
      )}
    </div>
  );
}

export default RevenueInsights;

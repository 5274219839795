/* eslint-disable @nx/enforce-module-boundaries */
import * as yup from 'yup';
import areaIcon from '../../../assets/icons/area.svg';
import bedroomIcon from '../../../assets/icons/bedroom.svg';
import bathroomIcon from '../../../assets/icons/bathroom.svg';
import { Link } from 'react-router-dom';
import arrowIcon from '../../../assets/icons/left-arrow.svg';
import { ROUTES } from '../../../api/routes';
import styles from './deal-details-unit-card.module.scss';
import { useTranslation } from 'react-i18next';
import { UnitDetails } from '@orascom/api-interfaces';
import { DealStatusEnum } from 'apps/orascom-broker-app/src/definitions/interfaces/deals.interface';
import Modal from 'libs/common-components/src/lib/modal/modal';
import { useContext, useState } from 'react';
import greenTick from '../../../assets/icons/green-tick.svg';
import { Field, Form, Formik } from 'formik';
import { Deal as DealUtils } from '../../../utils/deal';
import { ShareReservationLinlInterface } from 'apps/orascom-broker-app/src/definitions/interfaces/inputs/new-deal';
import { USER_CONTEXT } from 'libs/broker-sales-man-common-components/src/contexts/user-context';
import Numeral from 'numeral';

/* eslint-disable-next-line */
export interface DealDetailsUnitCardProps {
  unitDetails: UnitDetails;
  dealStatus: DealStatusEnum;
  leadName: string;
}

export function DealDetailsUnitCard(props: Readonly<DealDetailsUnitCardProps>) {
  const { t } = useTranslation();
  const [showContactModal, setShowContactModal] = useState(false);
  const [isContactSubmitted, setIsContactSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userContext = useContext(USER_CONTEXT);

  const schema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Required'),
  });

  const handleShowContactModal = () => {
    setIsContactSubmitted(false);
    setShowContactModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowContactModal(false);
    document.body.style.overflow = '';
  };

  const submitHandler = (values: { email: string }) => {
    setIsSubmitting(true);
    const mappedData: ShareReservationLinlInterface = {
      lead_email: values.email,
      lead_name: props.leadName,
    };
    DealUtils.shareReservationLink(props.unitDetails.id.toString(), mappedData)
      .then(() => {
        setIsContactSubmitted(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <hr />
      <h3 className={styles['sub-title']}>{t('unitOfInterest')}</h3>
      <div className={styles['flex-between']}>
        <div className={styles['wrapper']}>
          <img
            className={styles['image']}
            src={props.unitDetails.gallery[0]}
            alt="Apartment"
            loading="lazy"
          />
          <div className={styles['content']}>
            <h5>
              {props.unitDetails.name} | {props.unitDetails.project.name}{' '}
            </h5>
            <h3>{props.unitDetails.unit_type.name}</h3>
            <h2>{`${Numeral(props.unitDetails.price).format('0,0')} ${
              props.unitDetails.currency
            }`}</h2>
            <div className={styles['details']}>
              {props.unitDetails?.built_up_area ? (
                <div>
                  <img src={areaIcon} alt="" role="presentation" />
                  {props.unitDetails.built_up_area} {t('mSquared')}
                </div>
              ) : null}
              {props.unitDetails?.bedrooms ? (
                <div>
                  <img src={bedroomIcon} alt="" role="presentation" />{' '}
                  {props.unitDetails.bedrooms} {t('bedrooms')}
                </div>
              ) : null}
              {props.unitDetails?.bathrooms ? (
                <div>
                  <img src={bathroomIcon} alt="" role="presentation" />
                  {props.unitDetails.bathrooms} {t('bathrooms')}
                </div>
              ) : null}
            </div>
            <Link
              to={ROUTES['UnitDetails'].getPath?.(props.unitDetails.id)}
              className={styles['anchor']}
            >
              <span className="anchor">{t('viewDetails')}</span>
              <img src={arrowIcon} alt="" role="presentation" />
            </Link>
          </div>
        </div>
        {props.dealStatus === DealStatusEnum.LEAD_VERIFIED &&
          userContext?.user?.abilities.reservation_link_share == 1 && (
            <button className="btn btn--navy" onClick={handleShowContactModal}>
              {t('shareReservationLink')}
            </button>
          )}
      </div>
      <Modal
        show={showContactModal}
        onClose={handleCloseModal}
        title={!isContactSubmitted ? t('shareReservationLink') : ''}
        broker
      >
        {isContactSubmitted && (
          <div className={styles['modal']}>
            <img src={greenTick} alt="" role="presentation" />
            <h3 className={styles['sub-title']}>
              {t('reservationLinkSuccessfullyShared')}
            </h3>
            <p className={styles['body']}>
              {t('yourLeadCanReserveTheUnitOnline')}
            </p>
            <div className={styles['buttons']}>
              <button className="btn btn--navy" onClick={handleCloseModal}>
                {t('continue')}
              </button>
            </div>
          </div>
        )}
        {!isContactSubmitted && (
          <div className={styles['modal']}>
            <p className={styles['body']}>{t('insertYourLeadEmailHere')}</p>

            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={schema}
              onSubmit={submitHandler}
            >
              {({ errors }) => (
                <Form>
                  <div className="form__input">
                    <label htmlFor="email">{t('email')}</label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email@example.com"
                    />
                    {errors.email && (
                      <div className="form__error">{errors?.email}</div>
                    )}
                  </div>
                  <div className={styles['buttons']}>
                    <button
                      className="btn btn--navy"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t('shareLink')}
                    </button>
                    <button className="anchor" onClick={handleCloseModal}>
                      {t('cancel')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {/* <div className="form__input">
              <label htmlFor="lead-email">{t('leadEmailAddress')}</label>
              <input
                type="email"
                placeholder="Johndoe@example.com"
                name="lead-email"
                id="lead-email"
              />
            </div>
            <div className={styles['buttons']}>
              <button
                className="btn btn--navy"
                onClick={() => setIsContactSubmitted(true)}
              >
                {t('shareLink')}
              </button>
              <button className="anchor" onClick={handleCloseModal}>
                {t('cancel')}
              </button>
            </div> */}
          </div>
        )}
      </Modal>
    </>
  );
}

export default DealDetailsUnitCard;

import { OptionValue } from "@orascom/api-interfaces";

export enum CommissionsStatus {
  LATE = 1,
  PENDING = 2,
  CACHED = 3,
}
export const commissionStatusOptions = [
  { label: 'Late', value: CommissionsStatus.LATE },
  { label: 'Pending', value: CommissionsStatus.PENDING },
  { label: 'Cached', value: CommissionsStatus.CACHED },
];

export interface AnalyticsFilterSalesManagers {
  id: number;
  name: string;
  title: string;
}

export interface TimeStamps {
  timestampStart: string;
  timestampEnd: string;
}

export function getTimeStampsForAnalytics(): TimeStamps {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 365);
  const timestampStart = Math.floor(startDate.getTime() / 1000).toString();
  const endDate = new Date();
  const timestampEnd = Math.floor(endDate.getTime() / 1000).toString();

  return {
    timestampStart,
    timestampEnd,
  };
}
export const parseFiltersInUrl = (filters: OptionValue[]) => {
  return filters.map((filter) => filter.value.toString());
};

import { Link, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bellIcon from '../../assets/icons/notifications.svg';
import styles from './header.module.scss';
import { useContext } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { NOTIFICATIONS_CONTEXT } from '../../contexts/notifications-context';

export interface HeaderProps {
  notificationPath: To;
}
export function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation();
  const { isRead } = useContext(NOTIFICATIONS_CONTEXT);
  const { user } = useContext(USER_CONTEXT);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>
        {t('hello')} {user?.name},
      </h1>
      <Link to={props.notificationPath} className={styles['notifications']}>
        {!isRead && <span className={styles['circle']} />}
        <img src={bellIcon} alt="" role="presentation" />
      </Link>
    </div>
  );
}

export default Header;

/**
 * @author Ahmed Serag
 * @date 2024-02-05
 * @description Implementation of analytics events related utilities.
 * @filename analytics-events.ts
 */

import { OptionValue } from '@orascom/api-interfaces';

export interface CommonEventParameters {
  name?: string;
  userId?: string;
  timestamp?: string;
  portal?: 'Shopper' | 'Broker' | 'Sales Man' | 'Shopper Analytics';
  pageName?: string;
  country?: string;
  destination?: string;
  neighborhood?: string;
}

export function analyticsBrokerFiltersCustomEvent(
  params: CommonEventParameters
) {
  try {
    (window as any).gtag('event', `broker_search_(${params.name})`, params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationCustomEvent', e);
  }
}

export const gtagFiltersParser = (filterValue: OptionValue[]) => {
  return filterValue
    .map((filter) => {
      for (const [key, value] of Object.entries(filter)) {
        if (key === 'label') {
          return value;
        }
      }
    })
    .toString();
};

import useAnalyticsPeriodFilter from './use-analytics-period-filter';
import CalendarIcon from '../../assets/icons/calendar-analytics.svg';
import ReportIcon from '../../assets/icons/download-report.svg';
import { useTranslation } from 'react-i18next';
import { addFilterPeriod, getButtonText } from '@orascom/utils';
import { Modal } from '@orascom/common-components';
import AnalyticsCalendar from '../analytics-calendar/analytics-calendar';
import AnalyticsFilter from '../analytics-filter/analytics-filter';
import { FilterValue, OptionValue } from '@orascom/api-interfaces';

export interface AnalyticsPeriodFilter {
  portal: 'broker' | 'shopper-analytics';
  filterUnitTypes?: FilterValue[];
  filterDestinations?: FilterValue[];
  handleSelectedDestinations?(value: OptionValue[]): void;
  handleSelectedUnitTypes?(value: OptionValue[]): void;
}
export function AnalyticsPeriodFilter(props: Readonly<AnalyticsPeriodFilter>) {
  const { t } = useTranslation();

  const {
    showPeriodModal,
    selectedDuration,
    currentParams,
    handleSetSelectedDuration,
    handleShowPeriodModal,
    handleCloseModal,
    handleDownloadReport,
    value,
    setValue,
    filtersParams,
  } = useAnalyticsPeriodFilter();

  const selectedDurationButton = getButtonText(
    filtersParams['durationType']?.toString()
  );
  return (
    <div>
      <div className="analytics__period-filter-wrapper">
        <div className="analytics__period-filter-wrapper__period-button">
          <h3 className="analytics__filter-heading">{t('choosePeriod')}</h3>
          <button
            onClick={handleShowPeriodModal}
            className="analytics__filter-button"
          >
            <img src={CalendarIcon} alt="" role="presentation" />
            <span>{selectedDurationButton}</span>
          </button>
        </div>
        {props.portal === 'broker' ? (
          <button
            onClick={handleDownloadReport}
            className="analytics__download-button"
          >
            <img src={ReportIcon} alt="" role="presentation" />
            {t('downloadReport')}
          </button>
        ) : (
          <div className="analytics__filter-by">
            <p>Filter By</p>
            <AnalyticsFilter
              filterDestinations={props.filterDestinations}
              filterUnitTypes={props.filterUnitTypes}
              handleSelectedDestinations={props.handleSelectedDestinations}
              handleSelectedUnitTypes={props.handleSelectedUnitTypes}
            />
          </div>
        )}
      </div>

      <Modal
        show={showPeriodModal}
        onClose={handleCloseModal}
        title="Choose Duration"
      >
        <>
          <AnalyticsCalendar
            value={value}
            setValue={setValue}
            handleSetSelectedDuration={handleSetSelectedDuration}
            selectedDuration={selectedDuration}
          />
          <div className="analytics__buttons-wrapper">
            <button
              onClick={() => {
                addFilterPeriod(value, currentParams, selectedDuration);
                handleCloseModal();
              }}
              className="btn btn--navy analytics__apply-text"
            >
              {t('applyChanges')}
            </button>
            <button
              onClick={handleCloseModal}
              className="analytics__cancel-btn"
            >
              {t('cancel')}
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default AnalyticsPeriodFilter;

/* eslint-disable @nx/enforce-module-boundaries */
import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ImageGallery from '../../components/image-gallery/image-gallery';
import {
  CountriesEnum,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../utils/unit';
import UnitInfo from '../../components/unit-details/unit-info';
import { Loader } from '@orascom/common-components';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import UnitPlan from '../../components/unit-plan/unit-plan';
import UnitSpecs from '../../components/unit-specs/unit-specs';
import UnitPaymentPlan from '../../components/unit-payment-plan/unit-payment-plan';
import styles from './unit-details.module.scss';
import MontenegroUnitPaymentPlan from 'libs/common-components/src/lib/montenegro-unit-payment-plan/montenegro-unit-payment-plan';
import { analyticsBackButtonCustomEvent } from '../../utils/analytics-events';
import { USER_CONTEXT } from 'libs/broker-sales-man-common-components/src/contexts/user-context';
import SimilarUnits from '../../components/similar-units/similar-units';

export function UnitDetails() {
  const [unit, setUnit] = useState<UnitDetailsInterface>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const unitSpecsRef = useRef<null | HTMLDivElement>(null);

  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms>();
  const handleSelectPaymentTerms = (paymentTerm: UnitPaymentTerms) => {
    setSelectedPaymentTerms(paymentTerm);
  };

  const { t } = useTranslation();

  const { unitId } = useParams();
  useEffect(() => {
    if (isNaN(Number(unitId))) {
      return;
    }
    UnitUtils.getUnitDetails(Number(unitId))
      .then((res) => setUnit(res))
      .catch(() => setError(true))
      .finally();

    UnitUtils.getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);
      })
      .catch(() => {
        setPaymentTermsError(true);
      })
      .finally(() => setLoading(false));
  }, [unitId]);

  const navigateToUnitSpecs = () => {
    unitSpecsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const unitCountry = unit?.project.destination.country.slug;
  if (error) {
    return <h1 className="empty-unit__placholder">{t('noUnitFound')}</h1>;
  }
  if (loading) {
    return <Loader />;
  }
  const unitGallery: string[] =
    unit?.gallery && unit?.gallery?.length > 0
      ? unit?.gallery
      : ([
          unit?.cover_image,
          unit?.hover_image,
          unit?.area_image,
          unit?.cover_image,
          unit?.hover_image,
        ] as string[]);

  const montenegroPaymentPlan = paymentTermsError ? (
    <div id="payment-plan" className={`${styles['wrapper']} card`}>
      <h3>No payment plan found for this unit</h3>
    </div>
  ) : (
    <MontenegroUnitPaymentPlan
      unitDetails={unit}
      paymentTerms={paymentTerms}
      handleSelectPaymentTerms={handleSelectPaymentTerms}
      selectPaymentTerms={selectedPaymentTerms}
    />
  );

  return (
    <div className={styles['wrapper']}>
      <Link
        to={ROUTES['PrimaryUnits'].path}
        className={styles['back-anchor']}
        onClick={() =>
          analyticsBackButtonCustomEvent({
            userId: userContext.user?.id.toString(),
            timestamp: Date.now().toString(),
            portal: 'Broker',
            pageName: location.pathname,
          })
        }
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('backToAllUnits')}</span>
      </Link>
      {unit && (
        <>
          <div className={styles['details']}>
            <ImageGallery images={unitGallery} />
            <UnitInfo unit={unit} navigateToUnitSpecs={navigateToUnitSpecs} />
          </div>

          {unit.masterplans?.length > 0 && (
            <UnitPlan title={t('floorPlan')} plans={unit.masterplans} />
          )}

          {unitCountry === CountriesEnum.MONTENEGRO ? (
            montenegroPaymentPlan
          ) : (
            <UnitPaymentPlan />
          )}
          <div className={`card ${styles['masterplan']}`}>
            <h3>{t('masterPlan')}</h3>
            <div className={styles['image']}>
              <img
                src={unit.masterplan_image}
                alt="masterplan"
                loading="lazy"
              />
            </div>
          </div>

          <UnitSpecs unit={unit} ref={unitSpecsRef} />
          <SimilarUnits
            price={unit.dollar_price}
            unitId={unit.id}
            designType={unit.design_type}
          />
        </>
      )}
    </div>
  );
}

export default UnitDetails;

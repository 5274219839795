import { useContext, useEffect, useState } from 'react';
import {
  DealInterface,
  DealStatusEnum,
  SaleStatusEnum,
  SalesInvoiceInterface,
} from '../../definitions/interfaces/deals.interface';
import { Link, useParams } from 'react-router-dom';
import Numeral from 'numeral';
import { format } from 'date-fns';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import greenBulletIcon from '../../assets/icons/green-bullet.svg';
import orangeBulletIcon from '../../assets/icons/orange-bullet.svg';
import DealDetailsUnitCard from '../../components/common/deal-details-unit-card/deal-details-unit-card';
import DealDetailsBreadcrumbs from '../../components/deal-details-breadcrumbs/deal-details-breadcrumbs';
import { Deal as DealUtils } from '../../utils/deal';
import styles from './deal-details.module.scss';
import { MeetingInterface } from '../../definitions/interfaces/inputs/new-deal';
import { Loader } from '@orascom/common-components';
import LeadScheduledMeetings from '../../components/lead-scheduled-meetings/lead-scheduled-meetings';
import LeadInfoCard from '../../components/lead-info-card/lead-info-card';
import DealDetailsInvoice from '../../components/deal-details-invoice/deal-details-invoice';
import SubmitInvoice from '../../components/submit-invoice/submit-invoice';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { DESTINATION_SLUG } from '@orascom/api-interfaces';

export function DealDetails() {
  const [leadInfo, setLeadInfo] = useState<DealInterface>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submittingInvoice, setSubmittingInvoice] = useState<boolean>(false);
  const { t } = useTranslation();
  const { dealId, countryId, duplicate, brokerId } = useParams();
  const [leadMeetings, setLeadMeetings] = useState<MeetingInterface[]>([]);
  const [salesInvoices, setSalesInvoices] = useState<SalesInvoiceInterface[]>(
    []
  );
  const userContext = useContext(USER_CONTEXT);

  useEffect(() => {
    if (dealId && countryId && brokerId) {
      const promises = [];
      setLoading(true);
      setSubmittingInvoice(false);
      promises.push(
        DealUtils.getLeadById(
          dealId,
          countryId,
          duplicate === '1' ? '1' : '0',
          brokerId
        )
          .then((res) => {
            setLeadInfo({
              ...res.data,
              ...res.data?.lead,
            });
            if (
              userContext?.user?.abilities?.invoice?.submit &&
              res.data.sales?.length
            ) {
              setSalesInvoices(res.data?.sales?.[0].sales_invoices ?? []);
            }
            setLoading(false);
          })
          .catch((err) => console.log(err))
      );
    }
  }, [dealId, submittingInvoice]);

  if (loading) {
    return <Loader />;
  }

  let dealStatus =
    leadInfo &&
    (leadInfo.sales?.length
      ? leadInfo.sales[0].deal_status
      : leadInfo.lead_status);

  const reservedSaleStatus =
    leadInfo &&
    (leadInfo.sales?.length ? leadInfo.sales[0].reserved_sale_status : '');

  const dealUnit =
    leadInfo?.sales?.length && leadInfo?.sales?.[0].unit
      ? leadInfo?.sales?.[0].unit
      : leadInfo?.interested_in_unit_details;

  const makadiOrOwestDeal =
    leadInfo?.sales?.[0]?.unit?.project.destination.slug ===
      DESTINATION_SLUG.OWEST ||
    leadInfo?.sales?.[0]?.unit?.project.destination.slug ===
      DESTINATION_SLUG.MAKADI_HEIGHTS;

  const submitInvoice =
    dealStatus === DealStatusEnum.DEAL_CLOSED ||
    (dealStatus === DealStatusEnum.LEAD_RESERVED &&
      leadInfo?.sales?.[0]?.contractual_date &&
      makadiOrOwestDeal) ||
    ((dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
      dealStatus === DealStatusEnum.INVOICE_APPROVED) &&
      makadiOrOwestDeal);

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('dealDetails')}</h1>
      <div className="card">
        <DealDetailsBreadcrumbs dealStatus={dealStatus as DealStatusEnum} />
        {dealStatus === DealStatusEnum.LEAD_VERIFIED && (
          <>
            {leadMeetings.length > 0 ? (
              <>
                <h3 className={styles['sub-title']}>{t('Deal Information')}</h3>
                <div className={styles['details']}>
                  <div className={styles['detail']}>
                    <p>{t('Deal Status')}</p>
                    <h3>
                      {' '}
                      <img
                        src={orangeBulletIcon}
                        alt=""
                        role="presentation"
                      />{' '}
                      {t('Pending Client Decision')}
                    </h3>
                  </div>
                </div>
                <hr />
              </>
            ) : null}

            <div className={styles['flex-between']}>
              <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
              <Link
                to={ROUTES['NewMeeting'].getPath?.(
                  dealId,
                  countryId,
                  duplicate,
                  brokerId
                )}
                className="btn btn--navy"
              >
                {t('newMeeting')}
              </Link>
            </div>
            {dealId &&
              countryId &&
              dealStatus === DealStatusEnum.LEAD_VERIFIED && (
                <LeadScheduledMeetings
                  dealId={dealId}
                  countryId={countryId}
                  leadMeetings={leadMeetings}
                  setLeadMeetings={setLeadMeetings}
                />
              )}

            <hr />
          </>
        )}
        {submitInvoice &&
          leadInfo?.sales?.[0].source_id &&
          dealId &&
          countryId && (
            <SubmitInvoice
              dealId={dealId}
              countryId={countryId}
              setLoading={setLoading}
              setSubmittingInvoice={setSubmittingInvoice}
              saleId={leadInfo?.sales?.[0].source_id}
              contractualDate={leadInfo?.sales?.[0].contractual_date?.toString()}
            />
          )}

        {(dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
          dealStatus === DealStatusEnum.INVOICE_APPROVED) &&
          dealId && (
            <DealDetailsInvoice
              loading={loading}
              salesInvoices={salesInvoices}
            />
          )}
        {dealStatus === DealStatusEnum.LEAD_RESERVED &&
        leadInfo?.sales?.length ? (
          <>
            <h3 className={styles['sub-title']}>{t('Reservation Status')}</h3>
            <div className={styles['details']}>
              <div className={styles['detail']}>
                <p>{t('Sale Status')}</p>

                <h3>
                  {reservedSaleStatus === SaleStatusEnum.RESERVED ? (
                    <>
                      <img src={greenBulletIcon} alt="" role="presentation" />{' '}
                    </>
                  ) : (
                    <>
                      <img src={orangeBulletIcon} alt="" role="presentation" />{' '}
                    </>
                  )}
                  {reservedSaleStatus?.replace(/_/g, ' ')}
                </h3>
              </div>
              {leadInfo?.sales[0].remaining_reservation_amount ? (
                <div className={styles['detail']}>
                  <p>{t('Remaining Reservation Amount')}</p>
                  <h3>
                    {Numeral(
                      leadInfo?.sales[0].remaining_reservation_amount
                    ).format('0,0')}{' '}
                    {t('egp')}
                  </h3>
                </div>
              ) : null}
              {leadInfo?.sales[0].remaining_due_date ? (
                <div className={styles['detail']}>
                  <p>{t('Remaining Reservation Due Date')}</p>
                  <h3>
                    {format(
                      new Date(leadInfo?.sales[0].remaining_due_date * 1000),
                      'dd MMMM yyyy'
                    )}
                  </h3>
                </div>
              ) : null}
              {reservedSaleStatus === SaleStatusEnum.RESERVED ? (
                <div className={styles['detail']}>
                  <p>{t('Contract Signing Date')}</p>
                  <h3>
                    {leadInfo?.sales[0].contract_sign_date
                      ? format(
                          new Date(
                            leadInfo?.sales[0].contract_sign_date * 1000
                          ),
                          'dd MMMM yyyy'
                        )
                      : null}
                  </h3>
                </div>
              ) : null}
            </div>
            <hr />

            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            {dealId && countryId && (
              <LeadScheduledMeetings
                dealId={dealId}
                countryId={countryId}
                leadMeetings={leadMeetings}
                setLeadMeetings={setLeadMeetings}
              />
            )}
            <hr />
          </>
        ) : null}
        <LeadInfoCard
          leadInfo={leadInfo}
          dealStatus={dealStatus as DealStatusEnum}
        />
        {dealUnit && dealStatus && leadInfo && (
          <DealDetailsUnitCard
            leadName={leadInfo.name}
            unitDetails={dealUnit}
            dealStatus={dealStatus}
          />
        )}
        {dealStatus === DealStatusEnum.LEAD_SUBMITTED && (
          <>
            <hr />
            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            <p className={styles['note--secondary']}>
              {t('leadIsUnderReviewByTeam')}
            </p>
          </>
        )}
        {(dealStatus === DealStatusEnum.DEAL_CLOSED ||
          dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
          dealStatus === DealStatusEnum.INVOICE_APPROVED) && (
          <>
            <hr />
            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            {dealId && countryId && (
              <LeadScheduledMeetings
                dealId={dealId}
                countryId={countryId}
                leadMeetings={leadMeetings}
                setLeadMeetings={setLeadMeetings}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DealDetails;

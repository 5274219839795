/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import UnitsSoldPerDestination from '../../components/units-sold-per-destination/units-sold-per-destination';
import DealLifeCycle from '../../components/deal-lifecycle/deal-lifecycle';
import { useEffect, useState } from 'react';
import RevenueInsights from '../../components/revenue-insights/revenue-insights';
import { useParams } from 'react-router-dom';
import { FilterValue, PeriodType } from '@orascom/api-interfaces';
import { AnalyticsInternationalSales } from '../../definitions/interfaces/analytics';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import BrokerInternationalSales from '../../components/common/broker-international-sales/broker-international-sales';
import {
  AnalyticsPeriodFilter,
  getTimeStampsForAnalytics,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';

export function SalesManagerRevenueAnalytics() {
  const { t } = useTranslation();

  const { durationType } = useParams();

  const { handleSetSelectedDuration, filtersParams } =
    useAnalyticsPeriodFilter();

  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);

  const [brokerageCompanies, setBrokerageCompanies] = useState<FilterValue[]>(
    []
  );

  const [internationalSales, setInternationalSales] =
    useState<AnalyticsInternationalSales>();

  const { timestampStart, timestampEnd } = getTimeStampsForAnalytics();

  useEffect(() => {
    if (durationType) {
      handleSetSelectedDuration(durationType as PeriodType);
    }
    Promise.all([
      AnalyticsSalesManagerUtils.getAnalyticsFilterDestinationsSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterUnitTypeSalesManager(),
      AnalyticsSalesManagerUtils.getAnalyticsFilterBrokerageSalesManager(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
        setBrokerageCompanies(res[2]);
      })
      .catch((err) => console.log(err));
  }, []);

  const startDateArg = filtersParams['start']?.toString() ?? timestampStart;
  const endDateArg = filtersParams['end']?.toString() ?? timestampEnd;

  useEffect(() => {
    AnalyticsSalesManagerUtils.getAnalyticsInternationalSalesManager({
      from: startDateArg,
      to: endDateArg,
    })
      .then((res) => {
        setInternationalSales(res);
      })
      .catch((err) => console.log(err));
  }, [filtersParams['start'], filtersParams['end']]);

  return (
    <div className="analytics">
      <div className="analytics__title">
        <h1>{t('revenue')}</h1>
      </div>

      <AnalyticsPeriodFilter portal="broker" />

      <div id="analytics">
        <RevenueInsights
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          filterBrokerages={brokerageCompanies}
        />
        <DealLifeCycle
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          filterBrokerages={brokerageCompanies}
        />
        <UnitsSoldPerDestination
          filterUnitTypes={filterUnitTypes}
          filterBrokerages={brokerageCompanies}
        />
        <div className="broker-analytic-wrapper">
          <h3 className="title">{t('internationalSales')}</h3>

          <BrokerInternationalSales
            internationalSales={internationalSales}
            currency={
              internationalSales ? internationalSales?.currency : t('egp')
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SalesManagerRevenueAnalytics;

import { useTranslation } from 'react-i18next';
import styles from './similar-units.module.scss';
import { useEffect, useState } from 'react';
import { PaginationPayload, UnitInterface } from '@orascom/api-interfaces';
import { toast } from 'react-toastify';
import { CardsSlider, Loader } from '@orascom/common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import { ROUTES } from '../../api/routes';

export interface SimilarUnitsProps {
  unitId: number;
  price: number;
  designType: string;
}
export function SimilarUnits(props: Readonly<SimilarUnitsProps>) {
  const { t } = useTranslation();
  const [units, setUnits] = useState<PaginationPayload<UnitInterface[]>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    UnitUtils.getSimilarUnits(
      props.unitId.toString(),
      props.price,
      props.designType
    )
      .then((res) => {
        setUnits(res);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.unitId]);
  if (loading) {
    return <Loader />;
  }
  if (units?.data.length === 0) {
    return null;
  }
  return (
    <div className={`${styles['similar-units-container']} card`}>
      <h2 className={styles['similar-units-container__title']}>
        {t('similarUnits')}
      </h2>
      {units && (
        <div className={styles['units-container']}>
          <CardsSlider className="similar-units-card">
            {units.data.map((unit) => (
              <PropertyCard
                key={unit.id}
                unit={unit}
                handleRemove={undefined}
                responsiveImages
                unitDetailsPath={ROUTES['UnitDetails'].getPath}
                addSavedUnit={UnitUtils.addSavedUnit}
                deleteSavedUnit={UnitUtils.deleteSavedUnit}
                portal="broker"
              />
            ))}
          </CardsSlider>
        </div>
      )}
    </div>
  );
}
export default SimilarUnits;

export interface Common {
  id: number;
}

export interface FieldValue extends Common {
  name: string;
}

export enum BrokerRoleEnum {
  MANAGER = 'manager',
  AGENT = 'agent',
}

export const MonthsNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import UnitsSoldPerDestination from '../../components/units-sold-per-destination/units-sold-per-destination';
import UnitsSoldPerUnitType from '../../components/units-sold-per-unit-type/units-sold-per-unit-type';
import DealLifeCycle from '../../components/deal-lifecycle/deal-lifecycle';
import CommisionsInsights from '../../components/commisions-insights/commisions-insights';
import { useContext, useEffect, useState } from 'react';
import RevenueInsights from '../../components/revenue-insights/revenue-insights';
import { useParams } from 'react-router-dom';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { FilterValue, PeriodType } from '@orascom/api-interfaces';
import { USER_CONTEXT } from 'libs/broker-sales-man-common-components/src/contexts/user-context';
import AgentsRanking from '../../components/analytics-agents-ranking/analytics-agents-ranking';
import BrokerAgentRanking from '../../components/broker-agent-ranking/broker-agent-ranking';
import {
  AnalyticsPeriodFilter,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';

export function BrokerAnalytics() {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);

  const { durationType } = useParams();

  const { downloadingReport, selectedDuration, handleSetSelectedDuration } =
    useAnalyticsPeriodFilter();

  const [filterAssignedBrokers, setFilterAssignedBrokers] = useState<
    FilterValue[]
  >([]);
  const [filterDestinations, setFilterDestinations] = useState<FilterValue[]>(
    []
  );
  const [filterUnitTypes, setFilterUnitTypes] = useState<FilterValue[]>([]);

  useEffect(() => {
    if (durationType) {
      handleSetSelectedDuration(durationType as PeriodType);
    }
    Promise.all([
      AnalyticsUtils.getAnalyticsFilterDestinations(),
      AnalyticsUtils.getAnalyticsFilterUnitTypes(),
      AnalyticsUtils.getAnalyticsFilterAssignedBrokers(),
    ])
      .then((res) => {
        setFilterDestinations(res[0]);
        setFilterUnitTypes(res[1]);
        setFilterAssignedBrokers(res[2]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="analytics">
      <div className="analytics__title">
        <h1>{t('analytics')}</h1>
      </div>

      <AnalyticsPeriodFilter portal="broker" />

      <div id="analytics">
        {userContext.user?.is_manager ? (
          <>
            <CommisionsInsights
              filterDestinations={filterDestinations}
              filterUnitTypes={filterUnitTypes}
              filterBrokerAgents={filterAssignedBrokers}
            />
            <RevenueInsights
              filterDestinations={filterDestinations}
              filterUnitTypes={filterUnitTypes}
              filterBrokerAgents={filterAssignedBrokers}
              downloadingReport={downloadingReport}
            />
          </>
        ) : null}

        <DealLifeCycle
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          filterBrokerAgents={filterAssignedBrokers}
        />
        <UnitsSoldPerDestination
          filterUnitTypes={filterUnitTypes}
          filterBrokerAgents={filterAssignedBrokers}
        />
        <UnitsSoldPerUnitType
          filterDestinations={filterDestinations}
          filterBrokerAgents={filterAssignedBrokers}
          selectedDuration={selectedDuration}
        />
        {userContext.user?.is_manager ? (
          <AgentsRanking
            filterDestinations={filterDestinations}
            filterUnitTypes={filterUnitTypes}
          />
        ) : (
          <BrokerAgentRanking
            filterDestinations={filterDestinations}
            filterUnitTypes={filterUnitTypes}
          />
        )}
      </div>
    </div>
  );
}

export default BrokerAnalytics;

import { Dispatch, SetStateAction, useState } from 'react';
import styles from '../../pages/deal-details/deal-details.module.scss';
// eslint-disable-next-line @nx/enforce-module-boundaries
import Modal from 'libs/common-components/src/lib/modal/modal';
import { useTranslation } from 'react-i18next';
import greenTick from '../../assets/icons/green-tick.svg';
import { Deal as DealUtils } from '../../utils/deal';

interface SubmitInvoiceInterface {
  dealId: string;
  countryId: string;
  saleId: string;
  contractualDate: string;
  setLoading: (value: boolean) => void;
  setSubmittingInvoice: Dispatch<SetStateAction<boolean>>;
}
function SubmitInvoice({
  dealId,
  countryId,
  setLoading,
  saleId,
  setSubmittingInvoice,
  contractualDate,
}: Readonly<SubmitInvoiceInterface>) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFilename, setSelectedFilename] = useState<string>('');
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [isInvoiceSubmitted, setIsInvoiceSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [invoiceFailed, setInvoiceFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { t } = useTranslation();
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFilename(file.name);
    }
  };

  const handleShowInvoiceModal = () => {
    setIsInvoiceSubmitted(false);
    setInvoiceFailed(false);
    setShowInvoiceModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    if (isInvoiceSubmitted) {
      setSubmittingInvoice(true);
    }
    setShowInvoiceModal(false);
    setSelectedFile(null);
    setSelectedFilename('');

    document.body.style.overflow = '';
  };

  const handleSubmitInvoice = () => {
    const formData = new FormData();
    formData.append('pdf_attachment', selectedFile as File);
    formData.append('sale_source_id', saleId);
    if (contractualDate) {
      formData.append('contractual_date', contractualDate);
    }
    if (dealId && countryId) {
      setIsSubmitting(true);
      DealUtils.submitLeadInvoice(dealId, countryId, formData)
        .then(() => {
          setLoading(true);
          setIsInvoiceSubmitted(true);
        })
        .catch((err) => {
          console.log(err);
          setInvoiceFailed(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <h3 className={styles['sub-title']}>{t('salesInvoiceStatus')}</h3>
      <div className={styles['flex-between']}>
        <p className={styles['note']}>{t('youHaveNotSubmittedInvoiceYet')}</p>
        <button className="btn btn--navy" onClick={handleShowInvoiceModal}>
          {t('submitInvoice')}
        </button>
      </div>

      <Modal
        show={showInvoiceModal}
        onClose={handleCloseModal}
        title={!isInvoiceSubmitted ? t('salesInvoiceSubmission') : ''}
        broker
      >
        {isInvoiceSubmitted && (
          <div className={styles['modal']}>
            <img
              className={styles['tick-img']}
              src={greenTick}
              alt=""
              role="presentation"
            />
            <h3 className={styles['sub-title']}>
              {t('invoiceSubmittedSuccessfully')}
            </h3>
            <p className={styles['body']}>
              {t('onceTheSubmittedInvoiceHasBeenConfirmedByOrascom')}
            </p>
            <div className={styles['buttons']}>
              <button className="btn btn--navy" onClick={handleCloseModal}>
                {t('continue')}
              </button>
            </div>
          </div>
        )}
        {invoiceFailed && (
          <div className={styles['modal']}>
            <h3 className={styles['sub-title']}>
              {t('invoiceFailedToSubmit')}
            </h3>
            <p className={styles['body']}>{errorMessage}</p>
            <div className={styles['buttons']}>
              <button className="btn btn--navy" onClick={handleCloseModal}>
                {t('continue')}
              </button>
            </div>
          </div>
        )}
        {!isInvoiceSubmitted && !invoiceFailed && (
          <div className={styles['modal']}>
            <p className={styles['body']}>
              {t('afterSubmittingTheFollowingClaim')}{' '}
              <strong>{t('30WorkingDays')}</strong>{' '}
              {t('toReceiveYourCommission')}
            </p>
            <div className="form__input">
              <label>{t('attachSalesInvoice')}</label>
              <div className={styles['upload']}>
                <div className={styles['placeholder']}>
                  {selectedFilename.length
                    ? selectedFilename
                    : t('uploadInvoicePdfInHere')}
                </div>
                <label htmlFor="file-upload" className={styles['label']}>
                  {t('chooseFile')}
                </label>
                <input
                  name="file-upload"
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  onChange={onFileChange}
                />
              </div>
            </div>
            <div className={styles['buttons']}>
              <button
                className="btn btn--navy"
                onClick={handleSubmitInvoice}
                disabled={isSubmitting}
              >
                {t('confirmSubmission')}
              </button>
              <button className="anchor" onClick={handleCloseModal}>
                {t('cancel')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default SubmitInvoice;

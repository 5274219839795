import { handleError } from '@orascom/utils';
import { Updates as UpdatesAPI } from '../api/updates';
import { UpdatesInterface } from '@orascom/api-interfaces';

export class Updates {
  public static getUpdates(): Promise<UpdatesInterface[]> {
    return UpdatesAPI.getUpdates()
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static readNotifications(
    notificationsIds: number[],
    isRead?: 0 | 1
  ): Promise<string> {
    return UpdatesAPI.readNotifications(notificationsIds, isRead)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

import { Destination } from '@orascom/api-interfaces';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import styles from './destinations-listing.module.scss';

export interface DestinationsListingProps {
  destinations: Destination[];
}

export function DestinationsListing({
  destinations,
}: Readonly<DestinationsListingProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      {Boolean(destinations.length) && (
        <>
          <h3 className={styles['title']}>{t('destinations')}</h3>
          <div className={styles['grid']}>
            {destinations.map((p) => (
              <div key={p.id} className={styles['project']}>
                <img
                  src={p.cover_image}
                  alt={p.name}
                  className={styles['image']}
                  loading="lazy"
                />
                <div className={styles['content']}>
                  <h4 className={styles['name']}>{p.name}</h4>
                  <div className={styles['buttons']}>
                    <Link
                      to={ROUTES['Destination'].getPath?.(p.slug)}
                      className={`btn btn--navy ${styles['view']}`}
                    >
                      {t('viewDestination')}
                    </Link>
                    <a
                      href={p.brochure}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className={`anchor ${styles['download']}`}
                    >
                      {t('downloadBrochure')}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DestinationsListing;
